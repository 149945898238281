import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby";

const PageImpresssum = ({ data, location }) => {

  const post = data.contentfulImpressum;

  return(
  <Layout location={location}>
    <Seo
      title={post.title}
      description=""
      ogImage="https://images.ctfassets.net/t2ifj85iosf3/Gx3Sc0gyL2KygjlYoItex/6e87f29d8f2062cd9c58ba8cbb82fbfc/E169.jpg?w=1200&h=630&fit=fill"
    />
    <div className="container container-document">
      <h1>{post.title}</h1>
      <div
        className="body-text"
        dangerouslySetInnerHTML={{
          __html: post.content.childMarkdownRemark.html
        }}
      />
    </div>
  </Layout>
)}

export default PageImpresssum

export const query = graphql`
  query( $language: String ) {
    contentfulImpressum(contentful_id: {eq: "3oBwVWJB8DJmA1UfOsJzWC"}, node_locale: {eq: $language}) {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;